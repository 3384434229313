import axios from 'axios';

export default {
    async search(query, userId) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/user/search?q="+query + '&userId='+userId).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async signin(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/signin", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async login(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/login", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async updateUser(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/update", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async updatePassword(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/update_password", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async forgotPassword(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/forgot_password", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async generateVerificationCode(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/generate_verification", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async deleteUser(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/delete", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getUser(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/profile", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async saveFirebaseToken(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/firebase/token", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getAllUserArtists(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/artist/all", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getAllFollowing(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/following/all", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getAllFollower(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/follower/all", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getUserGenres(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/albums/genres", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getHomepage(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/homepage", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getLibrary(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/library", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async uploadFileAvatar(file, userId) {
        let formData = new FormData();
        formData.append('file', file);
        return await axios.post(process.env.VUE_APP_ENDPOINT_API  + "/api/user/upload/avatar?userId="+userId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async uploadFileCover(file, userId) {
        let formData = new FormData();
        formData.append('file', file);
        return await axios.post(process.env.VUE_APP_ENDPOINT_API  + "/api/user/upload/cover?userId="+userId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async followUser(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/follow", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async unFollowUser(params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/user/unfollow", params).then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getCountries() {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/countries/all").then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    },
    async getCoordinatesCountry(latitude, longitude) {
        return await axios.get('https://nominatim.openstreetmap.org/reverse?lat=' + latitude + '&lon=' + longitude + '&format=json').then(response => {
            return response.data;
        }).catch(() => {
            console.log('Errore')
        });
    }
}
