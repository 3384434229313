export default {
	it: {
		search_artists: 'Ricerca artisti..',
		search_users: 'Ricerca utenti..',
		latest_releases: 'Ultime uscite',
		keep_listening: 'Continua ad ascoltare',
		songs_listened: 'Brani ascoltati',
		following: 'Seguiti',
		follow: 'Segui',
		followers: 'Followers',
		albums: 'Album',
		singles: 'Singoli',
		albums_completed: 'Album completati',
		discography: 'Discografia',
		library: 'Libreria',
		last_added: 'Ultimi aggiunti',
		latest_findings: 'Aggiunti di recente',
		add: 'Aggiungi',
		all: 'Tutto',
		view_all: 'Vedi tutti',
		favorites: 'Preferiti',
		users: 'Utenti',
		profile: 'Profilo',
		artists: 'Artisti',
		album: 'Album',
		my_artists: 'I miei artisti',
		my_collection: 'Collezione',
		followed_artists: 'Artisti seguiti',
		total_tracks_played: 'Totale brani riprodotti',
		albums_listened: 'Album ascoltati',
		your_favorite_genres: 'I tuoi generi preferiti',
		play_on_spotify: 'Riproduci su Spotify',
		settings: 'Impostazioni',
		notifications: 'Notifiche',
		lang: 'Lingua',
		tracks: 'Brani',
		login: 'Accedi',
		signin: 'Registrati',
		email: 'Email',
		password: 'Password',
		current_password: 'Password attuale',
		new_password: 'Nuova password',
		confirm_password: 'Conferma password',
		nickname: 'Nickname',
		warning: 'Attenzione',
		missing_data: 'Dati mancanti',
		alert_login_1: 'Questo utente non esiste 😥',
		alert_login_2: 'Inserisci la tua email e la tua password 🙏',
		alert_signin_1: 'La tua email è già registrata!',
		alert_signin_2: 'Inserisci il tuo nickname, email e password 🙏',
		cancel: 'Annulla',
		confirm: 'Ok',
		logout: 'Esci',
		country: 'Paese',
		save: 'Salva',
		error: 'Spiacenti si è verificato un errore 😥',
		success_user_update: 'Profilo aggiornato correttamente 😍',
		delete_account: 'Elimina l\'account',
		delete_account_message: 'Sei sicuro di vole eliminare il tuo account definitivamente? Perderai tutti i tuoi dati 😥',
		change_password: 'Cambia password',
		alert_change_password_1: 'Inserisci la passowrd attuale e quella nuova 🙏',
		alert_change_password_2: 'Le password non corrispondono 😥',
		alert_change_password_3: 'La password attuale non è corretta 😥',
		alert_change_password_4: 'Inserisci la tua email 🙏',
		success_change_password: 'La tua password è stata aggiornata 😍',
		forgot_password: 'Password dimenticata?',
		alert_forgot_password_1: 'Completa tutti i campi 🙏',
		verification_code: 'Codice di verifica',
		verification_code_info: 'Inserisci il codice che ti è arrivato come notifica',
		continue: 'Continua'
	},
	en: {
		search_artists: 'Search artists..',
		search_users: 'Search users..',
		latest_releases: 'Latest releases',
		keep_listening: 'Keep listening',
		songs_listened: 'Songs listened',
		following: 'Following',
		follow: 'Follow',
		followers: 'Followers',
		albums: 'Albums',
		singles: 'Singles',
		albums_completed: 'Albums completed',
		discography: 'Discography',
		library: 'Library',
		last_added: 'Last added',
		latest_findings: 'Recently added',
		add: 'Add',
		all: 'All',
		view_all: 'View all',
		favorites: 'Favorites',
		users: 'Users',
		profile: 'Profile',
		artists: 'Artists',
		album: 'Album',
		my_artists: 'My Artists',
		my_collection: 'collection',
		followed_artists: 'Followed artists',
		total_tracks_played: 'Total tracks played',
		albums_listened: 'Albums listened',
		your_favorite_genres: 'Your favorite genres',
		play_on_spotify: 'Play on spotify',
		settings: 'Settings',
		notifications: 'Notifications',
		lang: 'Language',
		tracks: 'Tracks',
		login: 'Login',
		signin: 'Signin',
		email: 'Email',
		password: 'Password',
		current_password: 'Current password',
		new_password: 'New password',
		confirm_password: 'Confirm password',
		nickname: 'Nickname',
		warning: 'Warning',
		missing_data: 'Missing Data',
		alert_login_1: 'Your user does not exist 😥',
		alert_login_2: 'Enter your email and password 🙏',
		alert_signin_1: 'Your email is already registered!',
		alert_signin_2: 'Enter your nickname, email and password 🙏',
		cancel: 'Cancel',
		confirm: 'Ok',
		logout: 'Logout',
		country: 'Country',
		save: 'Save',
		error: 'Sorry an error occurred 😥',
		success_user_update: 'Profile updated correctly 😍',
		delete_account: 'Delete account',
		delete_account_message: 'Are you sure you want to delete your account permanently? You will lose all your data 😥',
		change_password: 'Change password',
		alert_change_password_1: 'Enter current and new passord 🙏',
		alert_change_password_2: 'Passwords do not match 😥',
		alert_change_password_3: 'The current password is incorrect 😥',
		alert_change_password_4: 'Enter your email 🙏',
		success_change_password: 'Password has been updated 😍',
		forgot_password: 'Forgot password?',
		alert_forgot_password_1: 'Complete all fields 🙏',
		verification_code: 'Verification code',
		verification_code_info: 'Enter the code that came to you as a notification',
		continue: 'Continue'
	}
}