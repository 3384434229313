import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
    {
        path: '/',
        redirect: '/home'
    }, {
        path: '/artist',
        name: 'artist',
        component: () => import('@/views/Artist')
    }, {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login')
    }, {
        path: '/search',
        name: 'search',
        component: () => import('@/views/Search')
    }, {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/Privacy')
    }, {
        path: '/',
        component: () => import('@/components/Navbar'),
        children: [
            {
                path: '',
                name: 'library',
                redirect: '/library',
            }, {
                path: 'library',
                name: 'library',
                component: () => import('@/views/Library')
            }, {
                path: 'home',
                name: 'home',
                component: () => import('@/views/Home')
            }, {
                path: '/profile/:id',
                name: 'profile',
                component: () => import('@/views/Profile')
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;