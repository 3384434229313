import { Preferences } from '@capacitor/preferences';


export async function setLang(lang) {
    await Preferences.set({
        key: 'lang',
        value: lang,
    });
}

export async function getLang() {
    const { value } = await Preferences.get({ key: 'lang' });
    return value;
}

export async function getUserId() {
    const { value } = await Preferences.get({ key: 'user_id' });
    return value;
}

export async function removeUserId() {
    await Preferences.remove({ key: 'user_id' });
}

export async function getArtistId() {
    const { value } = await Preferences.get({ key: 'artist_id' });
    return value;
}

export async function setArtistId(artistId) {
    await Preferences.set({
        key: 'artist_id',
        value: artistId,
    });
}
