<template>
    <ion-app>
        <ion-router-outlet></ion-router-outlet>
    </ion-app>
</template>

<script>
import { getUserId, getLang } from "@/js/utils";
import { defineComponent } from 'vue';
import { isPlatform, IonApp, IonRouterOutlet } from '@ionic/vue';
import { PushNotifications } from '@capacitor/push-notifications';
import userApi from "@/js/user";
import eventBus from './js/eventBus.js';
export default defineComponent({
    name: 'App',
    components: { IonApp, IonRouterOutlet },
    async created() {
        let lang = await(getLang());
        if (lang) this.$i18n.locale = lang;
    },
    async mounted() {
        await this.initFireBaseNotification();
        eventBus.on('initFirebaseNotification', this.initFireBaseNotification);
    },
    methods: {
        async initFireBaseNotification() {
            let userId = await getUserId();
            if (
                userId &&
                isPlatform('capacitor')
            ) {
                let permStatus = await PushNotifications.checkPermissions();

                if (permStatus.receive === 'prompt')
                    permStatus = await PushNotifications.requestPermissions();

                if (permStatus.receive !== 'granted')
                    throw new Error('User denied permissions!');

                await PushNotifications.register();
                await PushNotifications.addListener('registration', async (registrationToken) => {
                    if (
                        registrationToken &&
                        registrationToken.value
                    ) {
                        let data = {
                            userId: userId,
                            token: registrationToken.value
                        }
                        await userApi.saveFirebaseToken(data);
                    }
                });
            } else {
                console.log('Push notifications are not supported on this platform.');
            }
        }
    }
});
</script>